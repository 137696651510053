<script lang="ts" setup>
  import {useI18n} from 'vue-i18n'
  import hotkeys from 'hotkeys-js'
  import {InjectionI18nTitle} from './utils/injectionKeys'
  import eventBus from './utils/eventBus'
  import {getElementLocales} from '@/locales'
  import useSettingsStore from '@/store/modules/settings'
  import useMenuStore from '@/store/modules/menu'
  import useTabbarStore from '@/store/modules/tabbar'

  const route = useRoute()

  const settingsStore = useSettingsStore()
  const menuStore = useMenuStore()
  const tabbarStore = useTabbarStore()
  const tabbar = useTabbar()

  const locales = computed(() => getElementLocales())

  const buttonConfig = ref({
    autoInsertSpace: true,
  })

  // 侧边栏主导航当前实际宽度
  const mainSidebarActualWidth = computed(() => {
    let actualWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--g-main-sidebar-width'))
    if (settingsStore.settings.menu.menuMode === 'single' || (['head', 'only-head'].includes(settingsStore.settings.menu.menuMode) && settingsStore.mode !== 'mobile')) {
      actualWidth = 0
    }
    return `${actualWidth}px`
  })

  // 侧边栏次导航当前实际宽度
  const subSidebarActualWidth = computed(() => {
    let actualWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--g-sub-sidebar-width'))
    if (settingsStore.settings.menu.subMenuCollapse && settingsStore.mode !== 'mobile') {
      actualWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--g-sub-sidebar-collapse-width'))
    }
    if (['only-side', 'only-head'].includes(settingsStore.settings.menu.menuMode) && settingsStore.mode !== 'mobile') {
      actualWidth = 0
    }
    if (
      settingsStore.settings.menu.subMenuOnlyOneHide
      && menuStore.sidebarMenus.length === 1
      && (
        !menuStore.sidebarMenus[0].children
        || menuStore.sidebarMenus[0]?.children.every(item => item.meta?.sidebar === false)
      )
    ) {
      actualWidth = 0
    }
    return `${actualWidth}px`
  })

  // 设置页面 title
  watch([
    () => settingsStore.settings.app.enableDynamicTitle,
    () => settingsStore.title,
  ], () => {
    if (settingsStore.settings.app.enableDynamicTitle && settingsStore.title) {
      const title = settingsStore.titleFirst
        ? settingsStore.title
        : settingsStore.settings.app.routeBaseOn !== 'filesystem'
          ? generateI18nTitle(route.meta.breadcrumbNeste?.at(-1)?.i18n, settingsStore.title)
          : generateI18nTitle(route.meta.i18n, settingsStore.title)
      document.title = `${title} - ${import.meta.env.VITE_APP_TITLE}`
    } else {
      document.title = import.meta.env.VITE_APP_TITLE
    }
    if (settingsStore.settings.tabbar.enable && settingsStore.titleFirst && settingsStore.title) {
      tabbarStore.editTitle({
        tabId: tabbar.getId(),
        title: settingsStore.title,
      })
    }
  })

  onMounted(() => {
    settingsStore.setMode(document.documentElement.clientWidth)
    window.onresize = () => {
      settingsStore.setMode(document.documentElement.clientWidth)
    }
    hotkeys('alt+i', () => {
      eventBus.emit('global-system-info-toggle')
    })
  })

  const {t, te} = useI18n()
  provide(InjectionI18nTitle, generateI18nTitle)

  function generateI18nTitle(key: string | undefined, defaultTitle: string | Function = t('route.undefined')) {
    return settingsStore.settings.toolbar.enableI18n && !!key && te(key)
      ? t(key)
      : typeof defaultTitle === 'function'
        ? defaultTitle()
        : defaultTitle
  }
</script>

<template>
  <el-config-provider :locale="locales[settingsStore.settings.app.defaultLang]"
                      :size="settingsStore.settings.app.elementSize" :button="buttonConfig">
    <RouterView
      :style="{
        '--g-main-sidebar-actual-width': mainSidebarActualWidth,
        '--g-sub-sidebar-actual-width': subSidebarActualWidth,
      }"
    />
    <system-info/>
  </el-config-provider>
</template>

<style>
  .el-input-number .el-input__inner {
    text-align: left !important;
  }

  .el-upload-list__item {
    display: block !important;
  }
</style>
