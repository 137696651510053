import ContextMenu from '@imengyu/vue3-context-menu'
import ElementPlus from 'element-plus'
import App from './App.vue'
import pinia from './store'
import router from './router'
import { useI18n } from './locales'
import api from './api'

import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
// 自定义指令
import directive from '@/utils/directive'
// 错误日志上报
import errorLog from '@/utils/error.log'
// 加载 svg 图标
import 'virtual:svg-icons-register'
// 加载 iconify 图标
import { downloadAndInstall } from '@/iconify'
import icons from '@/iconify/index.json'
// 全局样式
import 'uno.css'
import '@/assets/styles/globals.scss'

import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

const app = createApp(App)
app.use(ElementPlus)
app.use(pinia)
app.use(router)
useI18n(app)
app.use(ContextMenu)
app.use(Avue,{api})
directive(app)
errorLog(app)
if (icons.useType === 'offline') {
  for (const info of icons.collections) {
    downloadAndInstall(info)
  }
}

app.mount('#app')
